import React from 'react';
import { Box } from '@chakra-ui/react';
import Allroutes from './Pages/allRoutes/Allroutes';
// import Home from './Pages/home/Home';
// import { useLocation } from 'react-router-dom';


const App = () => {

  // let usertarget = JSON.parse(localStorage.getItem('user')) || {};

  // let { pathname } = useLocation();
  // console.log(pathname)

  return (
     <Box bg='#E2FFFF' h='-moz-max-content'>
          <Allroutes />
     </Box>
  )
}

export default App;