import React from 'react'
import { Box, Select, Text } from '@chakra-ui/react';

const Filter_type = ({handelFilter}) => {


    const AddFilter = (event) => {
        // console.log(event.target.value)
        handelFilter(event.target.value)
    }


    return (
        <Box px='3px' >
            <Text as='b' fontSize='12px'>Meeting type</Text>
            <Select size='sm' bg='teal.400' onChange={(e)=> AddFilter(e)}>
                <option value='All'>All</option>
                <option value='Office Work'>Outside Office</option>
            </Select>
        </Box>
    )
}

export default Filter_type