import React from 'react';
import { Navigate} from 'react-router-dom';

const PrivateRoute = ({ children }) => {
    if (localStorage) {
        let user = JSON.parse(localStorage.getItem('user')) || {};

        if (!user.email_verified) {
            return <Navigate to='/signin' />
        }
    }

    return children
}

export default PrivateRoute