
import React from 'react';
import { Box, Text, Flex} from '@chakra-ui/react';
// import { format } from 'date-fns'

import { BsCardChecklist } from "react-icons/bs";

const Card = ({ title, meetings, logo, total }) => {
// console.log({title, meetings, logo, total})
    // let [today, setToday] = useState(format(new Date(), 'yyyy-MM-dd'));

    return (
        <Box boxShadow='0 4px 8px rgba(129, 230, 217, 0.5)' rounded='md' bg='teal.200' borderRadius='5px' p={{base:'3px',md:'10px'}} w={{base:'30%',md:'300px'}}>
            <Flex justifyContent='space-evenly' alignItems='center' gap={{base:'0px',md:"10px"}}  textAlign='center' flexDirection={{base:'column',md:'row'}}>
                <Box bg='teal' borderRadius='50%' p="15px">
                    {logo ? logo : <BsCardChecklist fontSize='30px' color='white' />}
                </Box>
                <Box >
                    <Text as='b' fontSize={{base:'12px',md:'18px'}}>{title}</Text>
                    <Text as='b' fontSize='13px'></Text>
                    <Flex mt={{base:'1px',md:'2px'}} alignItems={{base:'center',md:'flex-start'}} justifyContent={{base:'center',md:'left'}}>
                            <Text as='b' fontSize='12px'>  Total : </Text>
                            <Flex justifyContent='center' alignItems='baseline' w='fit-content'>
                            <Text color='gray.700' as='b' fontSize={{base:'30px',md:'40px'}} >
                                {meetings && meetings.length}
                            </Text>
                           { total && <Text as='b'>/{total.length}</Text>}
                        </Flex>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    )
}

export default Card