

import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import {
    Box, Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Flex, Text
} from '@chakra-ui/react';
import { MdDateRange } from "react-icons/md";
const Datepicker = ({ date, handelDate, handelTimeline, start, end }) => {

    return (

            <Menu>
                <MenuButton fontSize='12px' fontWeight='bold' p='10px' pl='10px' pr='10px' w='fit-content' borderRadius='3px' bg='teal.400'>
                    <Flex gap='5px'>
                        <MdDateRange fontSize={'30px'} />
                        {start === '' || end === '' ? <Text>Select Date</Text> : <Flex gap='10px' align="center">  <Text as='b'>{end} To {start}</Text></Flex>}
                    </Flex>
                </MenuButton>

                <MenuList>

                    <DateRangePicker
                        onChange={item => handelDate([item.selection])}
                        ranges={date}
                        // scroll={true}
                        direction={'vertical'}
                        moveRangeOnFirstSelection={false}
                    />

                    <MenuItem bg='blue.300' w='fit-content' ml='10px' onClick={handelTimeline}>
                        Apply
                    </MenuItem>
                </MenuList>
            </Menu>

    )
}

export default Datepicker