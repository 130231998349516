import React, { useContext } from 'react';
import { Box, Select, Text } from '@chakra-ui/react';
import { BdmContext } from '../Pages/ContextProvider/AllBDM_Provider';



const Dropdown = ({ handelUser }) => {
    let {allbdm} = useContext(BdmContext);

    // const GetAllUserData = async () => {
    //     try {
    //         console.log(1234)
    //     } catch (err) {
    //         console.log({ Error: err.message })
    //     }
    // }

    return (
        <Box px='3px' >
            <Text as='b' fontSize='12px'> Select User</Text>
            <Select size='sm' onChange={(e) => handelUser(e.target.value)} bg='teal.400'>
                <option value='All'>All</option>
                {
                    allbdm && allbdm.map((ele) => (
                        <option key={ele.id} value={ele.id}>{ele.name}</option>
                    ))
                }
            </Select>
        </Box>
    )
}

export default Dropdown