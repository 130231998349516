import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from '../home/Home';
import GoogleBtn from '../auth/GoogleSignInButton';
import PrivateRoute from '../auth/authprovider/PrivateRoute';
import PageNotFound from '../home/PageNotFound';

const Allroutes = () => {
    return (

        <Routes>
            <Route path={`/`} element={<Navigate to='/home' />} />
            <Route path='/notfound' element={<PageNotFound />} />
            <Route path='*' element={<Navigate to='/notfound' />} />
            <Route path='/home' element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path='/signin' element={<GoogleBtn />} />
        </Routes>

    )
}

export default Allroutes