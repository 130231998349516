import React, { useContext } from 'react';
import { Box, Flex, Avatar, Button,Image } from '@chakra-ui/react';
import { AuthContext } from '../../Pages/auth/authprovider/Authantication';
import { useNavigate } from 'react-router-dom';
import Logo from './assets/logo.png'


const Navbar = ({ user }) => {

    let Navigate = useNavigate();
    const { logoutAuth } = useContext(AuthContext);

    let { picture } = user;

    const handelLogout = () => {
        logoutAuth()
        Navigate('/signin')
    }

    return (
        <Flex justifyContent='space-between' p='12px'  bgGradient='linear(to-r, teal.500, teal.300)' m='auto' alignItems='center' gap='10px'>
            <Box w='180px' ><Image w='100%' src={Logo} alt='logo' /></Box>
            <Flex gap='10px' alignItems='center'>
                <Box w='fit-content' borderRadius='50%' border='2px solid white' p='2px'> <Avatar name='Kent Dodds' src={picture} /></Box>
                <Button fontSize='13px' fontWeight='bold' borderRadius='3px' onClick={handelLogout} >LogOut</Button>
            </Flex>
        </Flex>
    )
}

export default Navbar