import React, { useState, useEffect, useContext } from 'react';
import { Box, Flex, Spacer, Text } from '@chakra-ui/react';

import Datepicker from '../../componunts/datepicker/Datepicker';
import Dropdown from '../../componunts/Dropdown';
import ExportBtn from '../../componunts/ExportBtn/ExportBtn';
import { format } from 'date-fns';
import Meeting_Table from './componunts/Meeting_Table';
import Filter_type from './componunts/Filter_type';
import { BdmContext } from '../ContextProvider/AllBDM_Provider';

const Planned_MeetingsTable = () => {
    let { allMeetings, setMyDate, mydate, handelTimeline, start_date, to_date } = useContext(BdmContext);

    let [userID, setUserID] = useState('All');
    let [Loading, setLoading] = useState(false);
    let [Meeting, setMeeting] = useState(allMeetings || []);
    let [filter, setFilter] = useState(null);


    const DateFormat = (dateStr) => {

        let date = new Date(dateStr);

        date.setTime(date.getTime() + (5.5 * 60 * 60 * 1000));

        // Format the date as "dd-mm-yyyy hh:mm AM/PM"
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        let year = date.getFullYear();

        let hours = date.getHours();
        let minutes = String(date.getMinutes()).padStart(2, '0');
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert to 12-hour format

        let formattedDate = `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
        return formattedDate
    };

    let plan_col = [
        {
            name: <Text as='b' fontSize='15px' >Name</Text>,
            selector: row => <Text fontSize='15px'>{row.name}</Text>,
            width: '450px'
            // sortable: true
        },
        {
            name: <Text as='b' fontSize='15px' >Contact</Text>,
            selector: row => <Text fontSize='15px'>{row.contactName}</Text>,
            // sortable: true
        },
        {
            name: <Text as='b' fontSize='15px' >Account Name</Text>,
            selector: row => <Text fontSize='15px'>{row.accountName}</Text>,
            // sortable: true
        },
        {
            name: <Text as='b' fontSize='15px' >Start Date</Text>,
            selector: row => <Text fontSize='15px'>{DateFormat(row.dateStart)}</Text>,
            // sortable: true
        },
        {
            name: <Text as='b' fontSize='15px' >End Date</Text>,
            selector: row => <Text fontSize='15px'>{DateFormat(row.dateEnd)}</Text>,
            // sortable: true
        },
    ];


    const handelDate = (val) => {
        setMyDate(val)
    };

    const handelUser = (val) => {
        setUserID(val);
    };

    const handelFilter = (type) => {
        setFilter(type);
    };


    useEffect(() => {

        const applayFilters = () => {

            let newData = allMeetings;
            if (userID !== 'All') {
                newData = newData.filter((ele) => ele.assignedUserId === userID);
            }
            if (filter !== 'All') {
                newData = newData.filter((ele) => ele.contactName !== filter);
            }
            setMeeting(newData);
        }

        applayFilters()

    }, [allMeetings, userID, filter]);


    return (
        <Box>
            <Flex gap='10px' justifyContent='left' alignItems='center' mb='10px'>
                <Datepicker date={mydate} start={start_date} end={to_date} handelDate={handelDate} handelTimeline={handelTimeline} />
                <Box w='fit-content'>
                    <Dropdown handelUser={handelUser} />
                </Box>

                <Filter_type handelFilter={handelFilter} />
                <Spacer />
                <ExportBtn data={Meeting} filename={`${format(new Date(), 'dd-mm-yyyy').toString()}-report.csv`} />
            </Flex>

            <Meeting_Table colm={plan_col} Meeting={Meeting} userID={userID} Loading={Loading} />
        </Box>
    )
}

export default Planned_MeetingsTable