import React from 'react';
import {
    Box, Text, Tabs, TabList, TabPanels, Tab, TabPanel
} from '@chakra-ui/react';
import TileBar from './componunts/TileBar';
import Navbar from '../../componunts/navbar/Navbar';
import Held_Meeting from './Held_Meeting';
import Planned_MeetingsTable from './Planned_MeetingsTable';
import Attendence from './Attendence';

const Home = () => {

    let user = JSON.parse(localStorage.getItem('user')) || {};

    return (
        <Box>
            <Box>
                <Navbar user={user} />
            </Box>
            <Box>
                <TileBar />
            </Box>

            <Box width={{ base: '100%', md: '99%' }} bg='teal.200' gap='7px' position='relative' m='auto' p='3px' borderRadius='3px'>

                <Tabs size='md' variant='enclosed' w='100%' p={{ base: '0', md: '2px' }} mt='0'>
                    <TabList>
                        <Tab><Text as='b'>Total Attendance</Text></Tab>
                        <Tab><Text as='b'>Planned Meetings</Text></Tab>
                        <Tab><Text as='b'>Held Meetings</Text></Tab>

                    </TabList>
                    <TabPanels>
                        {/* BDM attendance */}
                        <TabPanel p={{ base: '0px', md: '5px' }} mt={{ base: '5px', md: '10px' }}>
                            <Attendence />
                        </TabPanel>

                        <TabPanel p={{ base: '0px', md: '5px' }} mt={{ base: '5px', md: '10px' }}>
                            <Planned_MeetingsTable />
                        </TabPanel>
                        <TabPanel p={{ base: '0px', md: '5px' }} mt={{ base: '5px', md: '10px' }}>
                            <Held_Meeting />
                        </TabPanel>
                    </TabPanels>
                </Tabs>


            </Box>


        </Box>
    )
}

export default Home