import React, { createContext, useState, useEffect } from 'react';
import { getAllBDM, GetAllmeetings } from '../../request';
import { addDays, format } from 'date-fns';

const BdmContext = createContext();

const AllBDMProvider = ({ children }) => {
    const [allbdm, setAllbdm] = useState([]);
    const [allMeetings, setAllMeetings] = useState([]);
    const [mydate, setMyDate] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const [start_date, setStart_date] = useState(format(mydate[0].startDate, 'yyyy-MM-dd').toString());
    const [to_date, setTo_date] = useState(format(mydate[0].endDate, 'yyyy-MM-dd').toString());

    const getusers = async () => {
        try {
            const data = await getAllBDM();
            if (data.status === 200) {
                setAllbdm(data.data.list);
            }
        } catch (err) {
            console.log('Error fetching users:', err);
        }
    };

    const getplannedmeetings = async () => {
        try {
            const data = await GetAllmeetings(start_date, to_date);
            setAllMeetings(data?.data?.list || []);
        } catch (error) {
            console.log('Error fetching planned meetings:', error);
        }
    };

    // useEffect(() => {
    //     setStart_date(format(mydate[0].startDate, 'yyyy-MM-dd').toString());
    //     setTo_date(format(mydate[0].endDate, 'yyyy-MM-dd').toString());
    // }, [mydate]);

    const handelTimeline = () => {
        setStart_date(format(mydate[0].startDate, 'yyyy-MM-dd').toString())
        setTo_date(format(mydate[0].endDate, 'yyyy-MM-dd').toString())
    };

    useEffect(() => {
        getusers();
    }, []);

    useEffect(() => {
        getplannedmeetings();
    }, [start_date, to_date]);

    return (
        <BdmContext.Provider value={{ allbdm, allMeetings, setMyDate, mydate, handelTimeline, start_date, to_date }}>
            {children}
        </BdmContext.Provider>
    );
};

export { BdmContext, AllBDMProvider };
