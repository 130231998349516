
import React, { useState, useEffect } from 'react';
import { Box, Flex, Spacer, Text, Button } from '@chakra-ui/react';

import Datepicker from '../../componunts/datepicker/Datepicker';
import Dropdown from '../../componunts/Dropdown';
import ExportBtn from '../../componunts/ExportBtn/ExportBtn';
import { SingleUserReport } from '../../request';
import ViewModal from '../../componunts/modal/ViewModal';

import { addDays, format } from 'date-fns';
import Meeting_Table from './componunts/Meeting_Table';

const Held_Meeting = () => {

  let [userID, setUserID] = useState('');
  let [Loading, setLoading] = useState(false);
  let [Meeting, setMeeting] = useState([]);

  const [mydate, setMyDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection'
    }
  ]);
  let [start_date, setStart_date] = useState(format(mydate[0].startDate, 'yyyy-MM-dd').toString());
  let [to_date, setTo_date] = useState(format(mydate[0].endDate, 'yyyy-MM-dd').toString());


  let colm = [
    {
      name: <Text as='b' fontSize='15px' >Date</Text>,
      selector: row => <Text fontSize='15px'>{row.date}</Text>,
      // sortable: true
    },
    {
      name: <Text as='b' fontSize='15px'>BDM Name</Text>,
      selector: row => <Text fontSize='15px'>{row.bdm_name}</Text>,
      // sortable: true,
    },
    {
      name: <Text as='b' fontSize='15px'>First-Checkin Time</Text>,
      selector: row => <Text fontSize='15px'>{row.checkin_time}</Text>,
      // sortable: true,
    },
    {
      name: <Text as='b' fontSize='15px'>Last-checkout Time</Text>,
      selector: row => <Text fontSize='15px'>{row.checkout_time}</Text>,
      // sortable: true,
    },
    {
      name: <Text as='b' fontSize='15px'>Total Meeting Hrs</Text>,
      selector: row => <Text fontSize='15px'>{row.meeting_hours}</Text>,
      // sortable: true,
    },
    {
      name: <Text as='b' fontSize='15px'>Details</Text>,
      // selector: row => <Button colorScheme='teal' size='sm' onClick={() => ShowInfo(userID,row.date)}>View</Button>
      selector: row => row.meeting_hours !== '-' && row.meeting_hours !== '00:00:00' ? <ViewModal date={row.date} userID={userID} total={row.meeting_hours} /> : <Button size='sm' isDisabled={true}>view</Button>
    }
  ];


  const getSingleUserdata = async () => {
    setLoading(true)
    try {
      let data = await SingleUserReport(userID, start_date, to_date);
      if (data.status === 200) {
        setMeeting(data.data.response);
        // console.log(data.data.response)
        setLoading(false)
      }
    } catch (err) {
      // Navigate('/signin')
    }
  };


  const handelDate = (val) => {
    setMyDate(val)
  };

  const handelTimeline = () => {
    setStart_date(format(mydate[0].startDate, 'yyyy-MM-dd').toString())
    setTo_date(format(mydate[0].endDate, 'yyyy-MM-dd').toString())
  };

  const handelUser = (val) => {
    if (val === userID) {
      return;
    };
    setUserID(val)
  };


  useEffect(() => {
    if (userID) {
      getSingleUserdata()
    }
}, [userID, start_date, to_date]);




  return (
    <Box>

      <Flex gap='10px' justifyContent='left' alignItems='center' mb='10px'>
        <Datepicker date={mydate} start={start_date} end={to_date} handelDate={handelDate} handelTimeline={handelTimeline} />
        <Box w='fit-content'>
          <Dropdown handelUser={handelUser} />
        </Box>
        <Spacer />
        <ExportBtn data={Meeting} filename={`${format(new Date(), 'dd-mm-yyyy').toString()}-report.csv`} />
      </Flex>

      <Meeting_Table colm={colm} Meeting={Meeting} userID={userID} Loading={Loading} />
    </Box>
  )
}

export default Held_Meeting