import { Box,Flex} from '@chakra-ui/react'
import React from 'react';
import './Loading.css'

const Loader = () => {

    return (
        <Flex m='auto' w='100%' justifyContent='center' alignItems='center' h='390px' bg='gray.100'>
         <Box className="lds-ripple"><Box></Box><Box></Box></Box>
         {/* <Text as='b'>Loading ...</Text> */}
        </Flex>
    )
}

export default Loader