import React, { useState } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import Loader from '../../../componunts/loading/Loader';


const Meeting_Table = ({ colm, Meeting, userID, Loading }) => {

  return (
    <Box>
      {/* {
        userID === '' ?
          <Flex
            m='auto'
            justifyContent='center'
            h='400px' bg='teal.200'
            p='20px'
            alignItems='center'><Text as='b'>Select User</Text></Flex> :
          <Box h='fit-content' bg='teal.200'>
            {
              <DataTable
                columns={colm}
                data={Meeting}
                striped='true'
                pagination
                progressPending={Loading}
                progressComponent={<Loader />}
              />
            }

          </Box>
      } */}

      <DataTable
        columns={colm}
        data={Meeting}
        striped='true'
        pagination
        progressPending={Loading}
        progressComponent={<Loader />}
      />
    </Box>
  )
}

export default Meeting_Table