import React from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineHome } from "react-icons/ai";

const PageNotFound = () => {
    const Navigate = useNavigate();

    const handelredirect = () => {
        Navigate('/home')
    }

    return (
        <Box  p='100px' h='100vh'>
            <Box textAlign="center" >
                <Heading fontSize="4xl" color="red.500">
                    404 Not Found
                </Heading>
                <Text fontWeight='bold' mt="4">Sorry, the page you are looking for does not exist.</Text>
                <Button mt='10px' bg='red' color='white' borderRadius='3px' onClick={handelredirect} leftIcon={<AiOutlineHome size='25px' />}>Go To HomePage</Button>
            </Box>
        </Box>
    )
}

export default PageNotFound