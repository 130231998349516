import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, useDisclosure, Button, Box, TableContainer, Table, Thead, Th, Tbody, Tr, Tfoot, Td, Text, Flex
} from '@chakra-ui/react';
import { MeetingInfo } from '../../request';

const ViewModal = ({ userID, date, total }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [userInfo, setUserInfo] = useState([])


    const OpenModal = async () => {

        try {
            // console.log(userID, date);
            let data = await MeetingInfo(userID, date);
            if (data.status === 200) {
                setUserInfo(data.data.list)
                onOpen()
            }
        } catch (err) {
            console.log(err)
        }

    };



    const handeltime = (time) => {
        let val = time.split(' ');
        //    console.log(val[1])
        // console.log({ val })
        return val[1]
    };

    //     function getTimeDifference(time1, time2) {
    //    //time1>time2
    //         const date1 = new Date(time1);
    //         const date2 = new Date(time2);

    //         const timeDifferenceMilliseconds = date1-date2;

    //         const hoursDiff = Math.floor(timeDifferenceMilliseconds / 3600000);
    //         const minutesDiff = Math.floor((timeDifferenceMilliseconds % 3600000) / 60000);
    //         const secondsDiff = Math.floor((timeDifferenceMilliseconds % 60000) / 1000);

    //         console.log(hoursDiff,minutesDiff,secondsDiff)

    //       };


    function secondsToHMS(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    };


    const convertToIST = (utcTime) => {
        const utcDate = new Date(utcTime.replace(' ', 'T') + 'Z');
        const istTime = new Intl.DateTimeFormat('en-US', {
            timeZone: 'Asia/Kolkata',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
        }).format(utcDate);
        // console.log({ istTime })
        return istTime
    };

    const getDuration = (Date1, Date2) => {
        // Define two dates
        const date1 = new Date(Date1);
        const date2 = new Date(Date2);

        // Calculate the time difference in milliseconds
        const timeDifference = Math.abs(date1.valueOf() - date2.valueOf());

        // Convert milliseconds to seconds
        const secondsDifference = timeDifference / 1000;

        // console.log( secondsDifference);
        let res = secondsToHMS(secondsDifference)
        // console.log({res})
        return res;
    }

    return (
        <>
            <Button
                bg='teal.100'
                size='sm'
                onClick={() => OpenModal()}
                key='full'
            >View</Button>

            <Modal borderRadius='3px' onClose={onClose} size={{ base: '100%', md: '5xl' }} isOpen={isOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader borderTopRadius='3px' bg='teal.300'>Details for {date}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {
                            userInfo && <Box p={{ base: '0px', md: '10px' }} >
                                <Flex gap='20px' fontSize='13px'>
                                    <Text mb='10px'>Employee Name : {userInfo.length > 0 && userInfo[0].assignedUserName}</Text>
                                    <Text mb='10px'>Employee ID : {userID}</Text>
                                </Flex>
                                <TableContainer border='1px solid teal' h='200px' overflowY='scroll'>
                                    <Table >
                                        <Thead>
                                            <Tr bg='teal.100'>
                                                <Th>Location</Th>
                                                <Th>Contact</Th>
                                                <Th>Checkin Time</Th>
                                                <Th>checkout Time</Th>
                                                <Th>Type</Th>
                                                <Th>Total Meeting Hrs</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {userInfo.map((ele, index) =>

                                                <Tr p='5px' m='auto' key={ele.id}
                                                    fontSize='13px'
                                                //bg={index%2 !==0 ? 'teal.100':''}
                                                >
                                                    <Td textAlign='left'>{ele.accountName}</Td>
                                                    <Td textAlign='left'>{ele.contactName }</Td>
                                                    <Td textAlign='center'>{ele.checkintime ? convertToIST(ele.checkintime) : '--'}</Td>
                                                    <Td textAlign='center'>{ele.checkouttime ? convertToIST(ele?.checkouttime) : '--'}</Td>
                                                    {/* <Td>{convertToIST(ele.checkintime)}</Td> */}
                                                    {/* <Td>{getTimeDifference(ele.checkouttime,ele.checkintime)}</Td> */}
                                                    <Td textAlign='center'>{ele?.meetingtype}</Td>
                                                    {/* <Td textAlign='center'>{secondsToHMS(ele?.duration)}</Td> */}
                                                    <Td textAlign='center'>{ele.checkintime && ele.checkouttime ? getDuration(ele.checkintime, ele.checkouttime) :'--'
                                                    }</Td>

                                                </Tr>

                                            )}
                                        </Tbody>
                                        <Tfoot>
                                            <Tr fontWeight='bold'>
                                                <Th></Th>
                                                <Th></Th>
                                                <Th></Th>
                                                <Th color='black' textAlign='center' fontWeight='bold'>Total</Th>
                                                <Th color='black' fontWeight='bold' textAlign='center'>{total}</Th>
                                            </Tr>
                                        </Tfoot>
                                    </Table>
                                </TableContainer>
                            </Box>
                        }
                    </ModalBody>

                    <Button w='150px' m='auto' mb='20px' bg='teal.200' onClick={onClose}>Close</Button>

                </ModalContent>
            </Modal>
        </>
    )
}

export default ViewModal;